<template>
  <div class="questionnaire">
    <div class="edit-content">
      <top-bar :msg="popupData"></top-bar>
      <left-catalogue @get-catalogClick="catalogClick"></left-catalogue>
      <right-topic 
		:currentIsFixed="currentIsFixed" 
		:tabRightIndex="tabRightIndex" 
		@selectEvent="selectClick"
		@get-tabClick="tabClick">
		</right-topic>
      <div class="edit-box">
        <div class="title">
          <h2>{{titleText}}</h2>
          <div class="edit" @click="showPopup">
            <i></i><span>编辑</span>
          </div>
        </div>
        <el-scrollbar class="quest-scroll" v-loading="enterLoading">
          <div class="fixed-problem" v-if="currentIsFixed==4">
            <p class="tips" v-if="questionList.remark">{{questionList.remark}}</p>
            <ul class="list">
              <li v-for="(item, index) in questionList.list" :key="index">
                <p>{{ item.dict_name }}</p>
              </li>
            </ul>
          </div>
          <div class="item-box" v-if="currentIsFixed!=4">
			  <div v-if="questionList.length">
				  <div class="" v-for="(item, index) in questionList" :key="item.id">
					  <radio-item v-if="item.type==0" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></radio-item>
					  <checkbox-item v-if="item.type==1" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></checkbox-item>
					  <fill-in-item v-if="item.type==2||item.type==3" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></fill-in-item>
					  <radio-input-item v-if="item.type==4" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></radio-input-item>
					  <details-address-item v-if="item.type==5" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></details-address-item>
					  <details-calendar-item v-if="item.type==6" :msg="item" :num="index" :isEdit="currentIsFixed" :parentList="questionList" @deleteEmpty="deleteEmpty" @updateEvent="catalogClick" @moveEvent="moveClick"></details-calendar-item>
				  </div>
				<div v-if="questionList.length>0">
				  <div class="finish touch-hover" v-if="$store.getters.isItAnAdministrator" @click="enterEditClick">
					<span>完成编辑，添加发送名单</span>
				  </div>
				</div>
            </div>
			<div class="empty" v-if="questionList.length==0">
              <img src="@/assets/questionnaire/noData_icon.png"/>
              <p>暂未配置题目，请从右侧选题区域选择题型进行配置</p>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
	<add-popup :show="isShowPopup" :msg="popupData" title="编辑问卷" @close="closeClick" @change="enterClick"></add-popup>
  </div>
</template>

<script>
	import topicType from "./components/topicType.js"
	import {timeFormat} from "@/utils/index.js"
	import topBar from "./components/top-bar";
	import leftCatalogue from "./components/left-catalogue";
	import rightTopic from "./components/right-topic";
	import editBtnItem from "@/views/questionnaire/components/edit-btn-item.vue"
	import radioItem from "@/views/questionnaire/components/radio-item.vue"
	import checkboxItem from "@/views/questionnaire/components/checkbox-item.vue"
	import radioInputItem from "@/views/questionnaire/components/radio-input-item.vue"
	import fillInItem from "@/views/questionnaire/components/fill-in-item.vue"
	import detailsAddressItem from "@/views/questionnaire/components/details-address-item.vue"
	import detailsCalendarItem from "@/views/questionnaire/components/details-calendar-item.vue"
	import addPopup from "@/views/questionnaire/components/add-popup.vue"

export default {
	name: 'QuestionnaireEdit',
	components: {
		topBar, leftCatalogue, rightTopic, editBtnItem, radioItem, checkboxItem, fillInItem, detailsAddressItem, detailsCalendarItem, addPopup, radioInputItem
	},
	data() {
		return {
			isShowPopup: false,//编辑题目弹窗
			currentIsFixed: 1, // 当前目录是否为固定题目
			tabRightIndex: 0, // 自定题目/图库选题
			questionSearchValue: '', // 题库题目搜索
			questionList: [] ,// 题目列表
			enterLoading: false,//加载动画 
			popupData: {},//弹窗数据
			titleText: '',//标题
			puestionAllData: {catid: '', dirid: '', queid: ''},//问卷数据
		}
	  },
		mounted() {
			this.$store.commit('SET_QUESTION_STATU', 0);
			this.initData();
		},
		methods: {
      deleteEmpty(){
        this.questionList.pop();
      },
			initData() {
				let data = this.$route.query;
				this.$api.questionnaire_detail({id: data.id}).then(res=>{
					this.titleText = res.data.title;
					this.popupData = res.data;
					this.puestionAllData.queid = res.data.id;//记录问卷ID
					// this.popupData.start_time = timeFormat(res.data.start_time, 'yyyy-mm-dd hh:MM:ss');
					// this.popupData.end_time = timeFormat(res.data.end_time, 'yyyy-mm-dd hh:MM:ss');
					this.popupData.start_time = res.data.start_time;
					this.popupData.end_time = res.data.end_time;
          this.$store.commit('SET_QUESTION_TITLE_DATA', this.popupData);
				})
			},
			//移动
			moveClick(e) {
				if (e.type=='up'&&e.index!=0) {//上移
					let a = this.questionList[e.index-1];
					let b = this.questionList[e.index];
					this.questionList.splice(e.index, 1, a);
					this.questionList.splice(e.index-1, 1, b);
				} else if (e.type == 'down' && e.index!=this.questionList.length-1) {//下移
					let a = this.questionList[e.index+1];
					let b = this.questionList[e.index];
					this.questionList.splice(e.index, 1, a);
					this.questionList.splice(e.index+1, 1, b);
				} else if (e.type == 'before'&&e.index!=0) {//最前
					let a = this.questionList[0];
					let b = this.questionList[e.index];
					this.questionList.splice(e.index, 1, a);
					this.questionList.splice(0, 1, b);
				} else if (e.type == 'after' && e.index!=this.questionList.length-1) {//最后
					let a = this.questionList[this.questionList.length-1];
					let b = this.questionList[e.index];
					this.questionList.splice(e.index, 1, a);
					this.questionList.splice(this.questionList.length-1, 1, b);
				} else if (e.type == 'delete') {
					this.questionList.splice(e.index, 1);
				}
			},
			//右边添加选题回调
			selectClick(e) {
				this.questionList.push(e.data);
				if (e.touchType=='tiku') {
					let data = {
						title: e.data.title,
						require: e.data.require,
						type: e.data.type,
						catid: e.data.catid,
						dirid: this.puestionAllData.dirid,
						queid: this.puestionAllData.queid,
						option: e.data.options,
						order: this.questionList.length,
					}
					console.log('要传的数据',data);
					this.$api.questionAdd(data).then(res=>{
						this.$message({type: 'success', message: '添加成功！'});
					})
				}
				// const scrollTop = document.getElementById('content-id-'+this.questionList[this.questionList.length-1].id);
				// scrollTop.scrollIntoView();
			},
			//完成编辑
			enterEditClick() {
				let id = this.$route.query.id;
				this.$router.push({path: '/sendList', query: {id:id}});
			},
			// 问卷目录点击
			catalogClick(e) {
				this.puestionAllData.dirid = e.data.id;//记录目录ID
				this.currentIsFixed = e.data.type;
				this.enterLoading = true;
				this.questionList = [];
				if (e.data.interfaceType==1) {
					this.$api.questionnaire_basic_info_detail({id: e.data.id}).then(res=>{
						this.enterLoading = false;
						this.questionList = res.data;
					}).catch(err=>{
						this.enterLoading = false;
					})					
				} else {
					this.$api.questionnaire_dir_info({dir_id: e.data.id, id: e.id}).then(res=>{
						this.enterLoading = false;
						this.questionList = res.data;
					}).catch(err=>{
						this.enterLoading = false;
					})	
				}
			}, 
			// 自定题目/图库选题tab切换
			tabClick(index) {
				this.tabRightIndex = index;
			}, 
			//编辑题目弹窗确定
			enterClick(e) {
        console.log(e,121245)
				let data =  {
					id: this.$route.query.id,
					title: e.name,
					start_time: e.sTime,
					end_time: e.eTime
				}
				this.$api.questionnaire_save(data).then(res=>{
					this.isShowPopup = false;
					this.initData();
					this.$message({type: 'success', message: '编辑成功！'});
				})
			},
			//显示弹窗
			showPopup() {
				this.isShowPopup = true;
			},
			//关闭
			closeClick() {
				this.isShowPopup = false;
			}
	  }
	}
</script>

<style lang="scss" scoped>
.index-main {
  height: 100vh;
  .el-card__body, .el-main {
    padding: 0;
  }
}

.questionnaire {
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;
  font-size: 12px;
  background-color: #f1f4f7;
}

.edit-content {
  height: 100%;
  .edit-box {
    margin: 106px 296px 0;
    background-color: #ffffff;
    .title {
      position: relative;
      height: 70px;
      line-height: 70px;
      text-align: center;
      border-bottom: 1px solid #EDF1F4;
      h2 {
        font-size: 18px;
        font-weight: 400;
        color: #242D33;
      }
      .edit {
        position: absolute;
        right: 22px;
        top: 21px;
        width: 72px;
        height: 28px;
        line-height: 26px;
        border: 1px solid #CEDAE0;
        border-radius: 2px;
        color: #6C7880;
        cursor: pointer;
        i {
          display: inline-block;
          vertical-align: middle;
          margin: 0 8px 2px 0;
          width: 12px;
          height: 12px;
          background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -120px -10px;
        }
        &:hover {
          border-color: #1190F3;
          color: #1190F3;
          i {
            background-position-y: -30px;
          }
        }
      }
    }
    .finish {
      padding: 40px 0;
      span {
        display: block;
        margin: 0 auto;
        width: 240px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 2px;
        background-color: #3984F5;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        transition: all 0.3s ease;
        &:hover {
          background-color: #1C72F1;
        }
      }
    }
    .fixed-problem {
      .tips {
        margin: 26px 30px 12px;
        padding-left: 10px;
        height: 44px;
        line-height: 44px;
        background: #FFF5F5;
        font-size: 14px;
        color: #FD5451;
      }
      .list {
        margin: 0 50px;
        font-weight: bold;
        font-size: 14px;
        color: #242D33;
        line-height: 36px;
      }
    }
  }
  .item-box {
    .empty {
      padding: 138px 0 0;
      text-align: center;
      font-size: 14px;
      color: #444F57;
      p {
        margin-top: 28px;
      }
    }
    .handle_area {
      &.unfold {
        .handle {
          display: none;
        }
        .i_edit {
          display: block;
        }
      }
      &:hover {
        .i_con {
          .handle {
            opacity: 1;
          }
        }
      }
    }
    .i_con {
      padding: 24px 20px 20px;
      border-bottom: 1px solid #edf1f4;
      .tt {
        height: 24px;
        line-height: 24px;
        font-size: 14px;
      }
      .tag {
        display: inline-block;
        margin-right: 6px;
        height: 22px;
        line-height: 22px;
        padding: 0 7px;
        font-size: 12px;
      }
      .txt {
        margin-left: 74px;
      }
      .handle {
        margin-left: 74px;
        margin-top: 4px;
        opacity: 0;
        .edit {
          line-height: 30px;
          color: #3984F5;
          text-decoration: underline;
          cursor: pointer;
        }
        .fr {
          border: 1px solid #dbe1e5;
          border-right: 0;
          border-radius: 2px;
          overflow: hidden;
        }
        span {
          float: left;
          width: 65px;
          height: 28px;
          line-height: 28px;
          border-right: 1px solid #dbe1e5;
          text-align: center;
          color: #98a0a6;
          cursor: pointer;
          &:hover {
            color: #3984F5;
            i {
              background-position-y: -150px;
            }
          }
        }
        i {
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 20px;
          background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -20px -130px;
        }
        .ico2 {
          background-position-x: -40px;
        }
        .ico3 {
          background-position-x: -60px;
        }
        .ico4 {
          background-position-x: -80px;
        }
        .ico5 {
          background-position-x: -100px;
        }
      }
    }
    .i_edit {
      display: none;
      position: relative;
      padding: 20px;
      background-color: #fafbfd;
      border-bottom: 1px solid #edf1f4;
      &:before {
        content: '';
        position: absolute;
        left: 92px;
        top: -12px;
        width: 20px;
        height: 13px;
        background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -80px -30px;
      }
      textarea {
        display: block;
        margin-bottom: 10px;
        width: 100%;
        height: 100px;
        padding: 10px;
        box-sizing: border-box;
      }
      .must {
        margin-bottom: 10px;
        .cho {
          line-height: 34px;
          cursor: pointer;
          i {
            float: left;
            margin-top: 9px;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border-radius: 2px;
            border: 1px solid #dbe1e5;
            background-color: #fff;
          }
          &.on {
            i {
              background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -100px -30px;
              border-color: #3984F5;
            }
          }
        }
        input {
          height: 32px;
          line-height: 32px;
          text-indent: 10px;
        }
      }
      table {
        margin-bottom: 12px;
        width: 100%;
        th {
          background-color: #edf1f4;
          height: 34px;
          color: #60696f;
          font-weight: 400;
          &.align_l {
            padding: 0 12px;
          }
        }
        td {
          height: 34px;
          padding-top: 12px;
        }
        .align_l {
          text-align: left;
        }
        .icon {
          display: block;
          width: 30px;
          height: 30px;
          background: url("~@/assets/questionnaire/survey_icon.png") no-repeat 0 -170px;
        }
        .delete {
          background-position-x: -30px;
        }
        .up {
          background-position-x: -60px;
        }
        .down {
          background-position-x: -90px;
        }
        .input {
          padding-right: 18px;
          div {
            margin-right: 80px;
          }
          input {
            display: block;
            width: 100%;
            height: 34px;
            box-sizing: border-box;
            text-indent: 11px;
          }
          i {
            float: right;
            margin: 2px;
          }
        }
        tbody {
          tr {
            &:first-child {
              .delete {
                width: 0;
                margin-left: 32px;
              }
            }
          }
        }
      }
      .group_s {
        padding: 3px 0 17px;
        a {
          color: #3984F5;
          cursor: pointer;
        }
      }
      .btn {
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #3984F5;
        color: #fff;
        border-radius: 2px;
        cursor: pointer;
      }
    }
    .type1 {
      .i_con {
        .tag {
          border: 1px solid #7166ff;
          color: #7166ff;
        }
      }
    }
    .type2 {
      .i_con {
        .tag {
          border: 1px solid #609DF7;
          color: #609DF7;
        }
      }
    }
    .type3 {
      .i_con {
        .tag {
          border: 1px solid #1C72F1;
          color: #1C72F1;
        }
      }
    }
    .type4 {
      .i_con {
        .tag {
          border: 1px solid #1190F3;
          color: #1190F3;
        }
      }
    }
    .type5 {
      .i_con {
        .tag {
          border: 1px solid #fb991a;
          color: #fb991a;
        }
      }
    }
    .type6 {
      .i_con {
        .tag {
          border: 1px solid #9c2dfa;
          color: #9c2dfa;
        }
      }
    }
    .type7 {
      .i_con {
        .tag {
          border: 1px solid #04CD83;
          color: #04CD83;
        }
      }
    }
    .option {
      margin-top: 8px;
      li {
        line-height: 32px;
        a {
          cursor: pointer;
        }
      }
      .ico {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        margin-bottom: 2px;
        width: 14px;
        height: 14px;
        border: 1px solid #dbe1e5;
      }
      &.radio {
        .ico {
          border-radius: 50%;
        }
        .on {
          .ico {
            border-color: #3984F5;
            i {
              display: block;
              margin: 3px;
              width: 8px;
              height: 8px;
              background-color: #3984F5;
              border-radius: 50%;
            }
          }
          .input {
            display: block;
          }
        }
      }
      &.checkbox {
        .ico {
          border-radius: 2px;
        }
        .on {
          .ico {
            background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -100px -30px;
            border-color: #3984F5;
          }
        }
      }
      .input {
        display: none;
        margin-top: 11px;
        input {
          width: 418px;
          height: 32px;
          line-height: 32px;
          border-radius: 0;
          text-indent: 10px;
        }
      }
    }
    .gap {
      padding: 12px 0 1px;
      li {
        display: flex;
        margin: 6px 0;
        padding: 0 10px;
        width: 398px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #dbe1e5;
        border-radius: 2px;
        color: #98a0a6;
        div {
          flex: 1;
        }
        input {
          width: 100%;
          height: 32px;
          line-height: 32px;
          border: 0;
        }
      }
    }
    .site {
      padding: 8px 0 6px;
      .down_select {
        margin-top: 10px;
        margin-right: 10px;
        .has {
          width: 108px;
        }
      }
      input {
        margin-top: 10px;
        width: 358px;
        height: 32px;
        line-height: 32px;
        text-indent: 10px;
      }
    }
    .date {
      padding: 18px 0 6px;
      input {
        width: 358px;
        height: 32px;
        line-height: 32px;
        text-indent: 10px;
      }
    }
  }
}

::v-deep .quest-scroll {
  height: calc(100vh - 255px);
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__bar.is-horizontal{
    height: 0;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
.touch-hover{cursor: pointer;}
</style>
