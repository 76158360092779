<template>
  <div :class="['checkbox-item-main', isEdit==0?'hover-show':'']">
    <div class="text-box-content flex">
      <div class="title-type">多项选择</div>
      <div class="" style="flex: 1;">
        <div class="title-content flex flex-col-center">
          <div class="title-text-content">
            <span class="bt-text">{{ num + 1 }}.</span>
            <span class="bt-text">{{ allData.title ? allData.title : '请输入标题' }}</span>
            <span class="bi-tian-text" v-if="allData.require==1">（必填）</span>
          </div>
        </div>
        <div class="radio-content">
          <el-checkbox-group v-model="checkList">
            <el-checkbox :label="item.title" v-for="(item, index) in allData.options" :key="index">
              {{ item.title ? item.title : '请填写选项标题' }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="edit-btn-content flex flex-col-center flex-row-between" v-if="!isShowEdit">
          <div class="edit-btn touch-hover" @click="editClick" v-if="msg.catid==0">点击编辑问题</div>
          <div class="move-content flex flex-col-center">
            <span class="move up" @click="moveClick(msg, 0)"><i class="ico1"></i>上移</span>
            <span class="move down" @click="moveClick(msg, 1)"><i class="ico2"></i>下移</span>
            <span class="move first" @click="beginClick(msg, 0)"><i class="ico3"></i>最前</span>
            <span class="move last" @click="beginClick(msg, 1)"><i class="ico4"></i>最后</span>
            <span class="delete" @click="deleteItemClick(msg)"><i class="ico5"></i>删除</span>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-box-content " v-if="isShowEdit">
      <textarea class="textarea-input" v-model="titleInput" placeholder="请输入标题"></textarea>
      <div class="bi-tian-select">
        <el-checkbox v-model="isBiTian">要求必填</el-checkbox>
      </div>
      <div class="select-set-content flex flex-col-center flex-row-between">
        <div class="set-title">选项设置</div>
        <div class="">
          <span class="yi-text">上移</span>
          <span class="yi-text">下移</span>
          <span class="yi-text-r">关联产品</span>
        </div>
      </div>
      <div class="input-content flex flex-col-center flex-row-between" v-for="(item, index) in allData.options"
           :key="index">
        <input class="input-text" type="text" v-model="item.title" placeholder="请填写选项标题"/>
        <div class="fun-content flex flex-col-center">
          <div class="flex flex-col-center">
            <img class="fun-img" src="@/assets/enterpriseCon/add_icon.png" alt="" @click.stop="addClick(item, index)"/>
            <img class="fun-img" src="@/assets/enterpriseCon/minus_icon.png" alt=""
                 @click.stop="deleteClick(item, index)"/>
          </div>
          <div class="fun-left flex flex-col-center">
            <img class="fun-img" src="@/assets/enterpriseCon/moveup_icon.png" alt=""
                 @click.stop="upClick(item, index)"/>
            <img class="fun-img" src="@/assets/enterpriseCon/Down_icon.png" alt=""
                 @click.stop="downClick(item, index)"/>
          </div>
          <span class="gl-btn"
                @click="openCorrelation(item, index)">{{ formatEchoData(item, 'item.product_id', 'Array').length > 0 ? '已关联(' + item.product_id.length + ')' : '点击关联' }}</span>
        </div>
      </div>
      <button class="enter-btn touch-hover" @click="enterEditClick">完成编辑</button>
    </div>

    <select-product-popup :show="correlationData.show" :checked="correlationData.checked" @enterEvent="enterClick"
                          @close="closeClick"></select-product-popup>
  </div>
</template>

<script>
import selectProductPopup from "@/views/questionnaire/components/select-product-popup.vue";

export default {
  name: 'checkbox-item',
  components: {selectProductPopup},
  props: {
    msg: {
      type: Object,
      default: {}
    },
    num: {
      type: [Number, String],
      default: 1
    },
    isEdit: {
      type: [Number, String],
      default: 0
    },
    parentList: [],//父亲数据列表
  },
  data() {
    return {
      checkList: [],
      titleInput: '',//标题
      isBiTian: false,//是否必填
      isShowEdit: false,//是否显示编辑
      inputList: [{title: ''}],//添加内容条数据
      allData: {},
      correlationData: {show: false, data: {}, checked: []},//关联数据
    }
  },
  mounted() {
    this.isShowEdit = this.msg.isEdit;
    this.allData = this.msg;
  },
  methods: {
    //编辑问题
    editClick() {
      this.isShowEdit = true;
      this.titleInput = this.allData.title;
    },
    //关联确定事件
    enterClick(e) {
      this.correlationData.data.product_id = e
    },
    //关闭弹窗
    closeClick() {
      this.correlationData.show = false;
    },
    //关联
    openCorrelation(e, index) {
      console.log('关联', e)
      this.correlationData.show = true;
      this.correlationData.data = e;
      this.correlationData.checked = e.product_id;
    },
    //完成编辑
    enterEditClick() {
      this.isShowEdit = false;
      this.allData.title = this.titleInput;
      this.allData.require = this.isBiTian ? 1 : 0;
      for (let i = 0; i < this.allData.options.length; i++) {
        this.allData.options[i]['order'] = i + 1;
      }
      let data = {
        title: this.allData.title,
        require: this.allData.require,
        type: this.msg.type,
        catid: this.$parent.$parent.puestionAllData.catid,
        dirid: this.$parent.$parent.puestionAllData.dirid,
        queid: this.$parent.$parent.puestionAllData.queid,
        order: this.num + 1,
        option: this.allData.options
      }
      if (this.allData.id) {
        Object.assign(data, {id: this.allData.id});
        this.$api.questionEdit(data).then(res => {
          this.$emit('updateEvent', {
            data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit,},
            id: this.$route.query.id
          });
          this.$message({type: 'success', message: '编辑成功！'});
        })
      } else {
        this.$api.questionAdd(data).then(res => {
          this.$emit('updateEvent', {
            data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit,},
            id: this.$route.query.id
          });
          this.$message({type: 'success', message: '添加成功！'});
        }).catch(error => {
          this.$emit('deleteEmpty') //如果为空，则不添加
        });
      }
    },
    //添加
    addClick() {
      this.allData.options.push({title: '', isCheck: false});
    },
    //删除
    deleteClick(e, index) {
      if (this.allData.options.length == 1) return;
      this.allData.options.splice(index, 1);
    },
    //上移
    upClick(e, index) {
      if (index == 0) return;
      let a = this.allData.options[index - 1];
      let b = this.allData.options[index];
      this.allData.options.splice(index, 1, a);
      this.allData.options.splice(index - 1, 1, b);
    },
    //下移
    downClick(e, index) {
      if (index == this.allData.options.length - 1) return;
      let a = this.allData.options[index + 1];
      let b = this.allData.options[index];
      this.allData.options.splice(index, 1, a);
      this.allData.options.splice(index + 1, 1, b);
    },
    //最前、最后
    beginClick(e, num) {
      let data1 = {id: '', order: ''};
      let data2 = {id: '', order: ''};
      if (num == 0) {//最前
        data1.id = e.id;
        data1.order = this.parentList[0].order;
        data2.id = this.parentList[0].id;
        data2.order = e.order;
      } else {//最后
        data1.id = e.id;
        data1.order = this.parentList[this.parentList.length - 1].order;
        data2.id = this.parentList[this.parentList.length - 1].id;
        data2.order = e.order;
      }
      let data = {
        move: [data1, data2]
      }
      this.$api.questionMove(data).then(res => {
        let str = num == 0 ? 'before' : 'after';
        this.$emit('moveEvent', {type: str, data: e, index: this.num});
        // this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
      })
    },
    //上移、下移
    moveClick(e, num) {
      let data1 = {id: e.id, order: ''};
      let data2 = {id: '', order: ''};
      if (num == 0 && this.num != 0) {//上移
        data1.order = this.parentList[this.num - 1].order;
        data2.id = this.parentList[this.num - 1].id;
        data2.order = e.order;
      } else if (num == 1 && this.num != this.parentList.length - 1) {//下移
        data1.order = this.parentList[this.num + 1].order;
        data2.id = this.parentList[this.num + 1].id;
        data2.order = e.order;
      } else {
        return;
      }
      let data = {
        move: [data1, data2]
      }
      this.$api.questionMove(data).then(res => {
        let str = num == 0 ? 'up' : 'down';
        this.$emit('moveEvent', {type: str, data: e, index: this.num});
        // this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
      })
    },
    //删除题
    deleteItemClick(e) {
      this.$api.questionDel(e.id).then(res => {
        this.$message({type: 'success', message: '删除成功！'});
        this.$emit('moveEvent', {type: 'delete', data: e, index: this.num});
        // this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.checkbox-item-main {
  padding: 24px 0;
  border-bottom: 1px solid #EDF1F4;

  .text-box-content {
    padding: 0 20px;

    .title-type {
      flex-shrink: 0;
      height: 24px;
      background: #FFFFFF;
      border: 1px solid #7166FF;
      padding: 0 8px;
      line-height: 24px;
      font-size: 12px;
      color: #7166FF;
      margin-right: 11px;
    }

    .title-content {
      .title-text-content {
        .bt-text {
          font-size: 14px;
          color: #242D33;
          font-weight: bold;
        }

        .bi-tian-text {
          font-size: 14px;
          color: #242D33;
        }
      }
    }

    .radio-content {
      margin-top: 23px;
      flex: 1;
      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .el-checkbox {
          margin-bottom: 16px;
          display:flex;
          align-items:center;
          ::v-deep .el-checkbox__input{
            flex-shrink: 0;
            margin-top: 3px;
          }
          ::v-deep .el-checkbox__label{
            flex: 1;
            width: 980px;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-word;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .edit-btn-content {
      opacity: 0;

      .edit-btn {
        font-size: 12px;
        color: #3984F5;
        margin-top: 4px;
      }

      .move-content {
        border: 1px solid #DBE1E5;
        border-radius: 2px;
        line-height: 30px;

        .move {
          border-right: 1px solid #DBE1E5;
          width: 66px;
          height: 30px;
          text-align: center;

          i {
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            height: 20px;
            background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -20px -130px;
          }

          .ico2 {
            background-position-x: -40px;
          }

          .ico3 {
            background-position-x: -60px;
          }

          .ico4 {
            background-position-x: -80px;
          }

          .ico5 {
            background-position-x: -100px;
          }

        }

        .delete {
          width: 66px;
          text-align: center;
        }

        span:hover {
          color: #3984F5;
          cursor: pointer;

          i {
            background-position-y: -150px;
          }
        }
      }
    }
  }

  .edit-box-content {
    padding: 20px;
    background-color: #FAFBFD;

    .textarea-input {
      width: 100%;
      height: 100px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #CEDAE0;
      padding: 14px 12px;
      box-sizing: border-box;
    }

    .bi-tian-select {
      padding: 18px 0;
    }

    .select-set-content {
      height: 34px;
      background: #EDF1F4;
      padding: 0 12px;
      margin-bottom: 12px;

      .set-title {
        font-size: 12px;
        color: #6C7880;
      }

      .yi-text {
        font-size: 12px;
        color: #909DA5;
        margin-left: 11px;
      }

      .yi-text-r {
        font-size: 12px;
        color: #909DA5;
        margin-left: 48px;
      }
    }

    .input-content {
      padding-right: 12px;
      margin-bottom: 12px;

      .input-text {
        flex: 1;
        height: 34px;
        line-height: 34px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CEDAE0;
        padding: 0 12px;
      }

      .fun-content {
        margin-left: 10px;

        .fun-left {
          margin-left: 17px;
        }

        .fun-img {
          width: 21px;
          height: 21px;
          margin-left: 14px;

          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }

        .gl-btn {
          font-size: 12px;
          color: #3984F5;
          margin-left: 50px;

          &:hover {
            cursor: pointer;
            opacity: .7;
          }
        }
      }
    }

    .enter-btn {
      width: 100%;
      height: 40px;
      background: #3984F5;
      border-radius: 4px;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }
  }
}

.hover-show {
  &:hover {
    .edit-btn-content {
      opacity: 1;
    }
  }
}

.touch-hover {
  cursor: pointer;
}

</style>