<template>
  <div class="head clearfix">
    <div class="name oe1">{{ (msg.title || questionTitleData?.title || '')  + '（编号：' + (msg.id || questionTitleData?.id || '') + '）' }}</div>
    <span class="preview" @click="lookClick">预览问卷</span>
    <ul class="step">
      <li :class="[questionStatu==0?'cur':'']">
        <div class="item" @click="toPath(0)">
          <div class="ico0"></div>
          <p>编辑问卷</p>
        </div>
      </li>
      <li :class="[questionStatu==1?'cur':'']">
        <i></i>
        <div class="item" @click="toPath(1)">
          <div class="ico1"></div>
          <p>发送名单</p>
        </div>
      </li>
      <li :class="[questionStatu==2?'cur':'']">
        <i></i>
        <div class="item" @click="toPath(2)">
          <div class="ico2"></div>
          <p>发送问卷</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'topBar',
  props: {
    msg: {
      type: Object,
      default: () => {
        return {
          id: null,
          title: ''
        }
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    lookClick() {
      let pathInfo = this.$router.resolve({path: '/preview', query: {id: this.questionStatu === 2 ? this.$route.query.qid : this.$route.query.id}});
      window.open(pathInfo.href, '_blank');
    },
    async toPath(index) {
      const pathData = {
        0: {
          path: '/questionnaireEdit',
          param: 'id'
        },
        1: {
          path: '/sendList',
          param: 'id'
        },
        2: {
          path: '/sendQuestionnaire',
          param: 'qid'
        }
      }

      if (index === 2) {
        if (this.$store.state.qusestionList.length === 0){
          console.log(147894)
          this.$message({
            message: '请先完成编辑需要发送短信的企业名单',
            type: 'warning'
          });
          return;
        }
      }

      this.$router.push({
        path: pathData[index].path,
        query: {
          [pathData[index].param]: this.questionStatu === 2 ? this.$route.query.qid : this.$route.query.id
        }
      });
    }
  },
  computed: {
    questionStatu() {
      return this.$store.state.questionStatu;
    },
    questionTitleData() {
      return this.$store.state.questionTitleData;
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  position: absolute;
  top: 18px;
  left: 18px;
  right: 18px;
  height: 70px;
  background: #FFFFFF;

  .name {
    float: left;
    margin: 18px 10px 0 24px;
    padding: 0 20px;
    width: 378px;
    height: 32px;
    line-height: 32px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }

  .preview {
    float: left;
    margin-top: 18px;
    width: 94px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #3984F5;
    border-radius: 2px;
    color: #3984F5;
    text-align: center;

    &:hover {
      color: #FFFFFF;
      background: #3984F5;
      cursor: pointer;
    }
  }

  .step {
    float: left;
    margin-top: 14px;
    margin-left: 70px;

    li {
      float: left;
    }

    .item {
      float: left;
      width: 108px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      > div {
        margin: 0 auto 4px;
        width: 20px;
        height: 20px;
        background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -40px -10px;
      }

      .ico1 {
        background-position-y: -30px;
      }

      .ico2 {
        background-position-y: -50px;
      }
    }

    p {
      color: #6C7880;
    }

    i {
      float: left;
      margin-top: 10px;
      width: 15px;
      height: 10px;
      background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -20px 0;
    }

    .cur {
      .item {
        > div {
          background-position-x: -60px;
        }
      }

      p {
        color: #3984F5;
      }
    }
  }
}
</style>
