<template>
  <div class="left-catalog">
    <div class="title">问卷目录</div>
    <el-scrollbar class="quest-scroll">
      <div class="catalog">
        <div class="list">
          <ul>
            <li :class="[catalogIndex === index ? 'cur': '']" v-for="(item, index) in allData" :key="index" @click.stop="catalogClick(item, index)">
              <h4><span class="serial">{{ index+1+'、' }}</span>{{ item.title }}</h4>
              <p>已设置 {{ item.num }} 题</p>
              <div class="handle clearfix" v-if="item.type==0" @click.stop >
                <span class="edit" @click.stop="editClick(item, 1)">编辑</span>
                <span class="move up" @click.stop="moveDir(item, index, 0)">上移</span>
                <span class="move down" @click.stop="moveDir(item, index, 1)">下移</span>
                <!-- <span class="move first">最前</span> -->
                <!-- <span class="move last">最后</span> -->
                <span class="delete" @click.stop="deleteClick(item)">删除</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="create" @click="showCreateClick(0)">
          <i></i><span>创建目录</span>
        </div>
      </div>
    </el-scrollbar>
	<el-dialog :title="editTitle" :visible.sync="isShow" width="460px" :before-close="handleClose">
	  <input class="input-text" v-model="editText" type="text" placeholder="输入问卷目录标题" />
	  <span slot="footer" class="dialog-footer flex flex-col-center flex-row-center">
	    <el-button type="primary" @click="enterClick">保 存</el-button>
	    <el-button @click="isShow = false">取 消</el-button>
	  </span>
	</el-dialog>
	<el-dialog title="提示" :visible.sync="isShowTip" width="400px" :before-close="handleClose">
	  <div class="tip-title">是否确认删除</div>
	  <span slot="footer" class="dialog-footer flex flex-col-center flex-row-center">
	    <el-button type="primary" @click="deleteEnter">确定</el-button>
	    <el-button @click="isShowTip = false">取消</el-button>
	  </span>
	</el-dialog>
	
  </div>
</template>

<script>
	export default {
		name: 'leftCatalogue',
		data() {
			return {
				allData: [],
				catalogIndex: 0,
				isShow: false,
				editText: '',//编辑内容
				isShowTip: false,//提示弹窗
				deleteID: '',//删除的ID
				editTitle: '创建问卷目录',//创建问卷目录
				editType: {id: '', type: 0},//type:0添加目录，1编辑目录
			}
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				let data = this.$route.query;
				this.$api.questionnaire_basic_info().then(res=>{
          console.log(res,878)
					this.allData = [];
					this.allData = [res.data];
					this.$api.questionnaire_dir_list({id: data.id}).then(res=>{
						this.allData = this.allData.concat(res.data);
					})
					this.catalogClick(this.allData[0], 0);
				})
			},
      initData1() {
        let data = this.$route.query;
        this.$api.questionnaire_basic_info().then(res=>{
          console.log(res,878)
          this.allData = [];
          this.allData = [res.data];
          this.$api.questionnaire_dir_list({id: data.id}).then(res=>{
            this.allData = this.allData.concat(res.data);
            console.log(this.allData,142)
            this.catalogClick(this.allData[this.allData.length-1], this.allData.length-1);
          })
          // this.catalogClick(this.allData[0], 0);
        })
      },
			//移动目录
			moveDir(e, index, type) {
				let list = [];
				let data_1 = {id: '', sort: ''};
				let data_2 = {id: '', sort: ''};
				let a = {};
				let b = {};
				if (type==0) {//上移
					if (index==0 || this.allData[index-1].sort==0) return;
					data_1.id = this.allData[index].id;
					data_1.sort = this.allData[index-1].sort;
					data_2.id = this.allData[index-1].id;
					data_2.sort = this.allData[index].sort;
					list.push(data_1);
					list.push(data_2);
					a = this.allData[index-1];
					b = this.allData[index];
				} else {//下移
					if (index==this.allData.length-1) return;
					data_1.id = this.allData[index].id;
					data_1.sort = this.allData[index+1].sort;
					data_2.id = this.allData[index+1].id;
					data_2.sort = this.allData[index].sort;
					list.push(data_1);
					list.push(data_2);
					a = this.allData[index+1];
					b = this.allData[index];
				}
				this.$api.questionnaire_dir_move({move: list}).then(res=>{
					this.allData.splice(index, 1, a);
					if (type==0) this.allData.splice(index-1, 1, b);
					if (type!=0) this.allData.splice(index+1, 1, b);
				})
			},
			//编辑目录
			editClick(e, num) {
				this.showCreateClick(num, e);
			},
			//确认删除
			deleteEnter() {
				this.$api.questionnaire_del_dir(this.deleteID).then(res=>{
					this.isShowTip = false;
					this.initData();
					this.$message({type: 'success', message: '删除成功！'});
				})
			},
			//删除
			deleteClick(e) {
				this.deleteID = e.id;
				this.isShowTip = true;
			},
			// 问卷目录点击
			catalogClick(e, index) {
        console.log(e)
				this.catalogIndex = index;
				this.$emit('get-catalogClick', {data: e, index: index, id: this.$route.query.id});
			},
			//显示创建目录
			showCreateClick(e, data={}) {
				if (e==0) {
					this.editType.type = 0;
					this.editTitle = '创建问卷目录';
					this.editText = '';
				} else {
					this.editType.type = 1;
					this.editType.id = data.id;
					this.editTitle = '编辑问卷目录';
					this.editText = data.title;
				}
				this.isShow = true;
			},
			enterClick() {
				let data = {
					title: this.editText,
					question_id: this.$route.query.id,
					type: 0
				}
				if (this.editType.type==1) Object.assign(data, {id: this.editType.id});
				console.log('保存数据：', data);
				this.$api.questionnaire_save_dir(data).then(res=>{
					this.isShow = false;
					this.initData1();
          this.catalogClick(this.allData[5], 5);
          console.log(this.allData,14)
					let msg = this.editType.type==0?'新增成功！':'编辑成功！'
					this.$message({type: 'success', message: msg});
				})
			},
			handleClose(done){
				done();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.input-text{width: 400px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 0 12px;box-sizing: border-box;}
	.tip-title{font-size: 14px;color: #242D33;text-align: center;}
.left-catalog {
  position: absolute;
  left: 18px;
  top: 106px;
  bottom: 18px;
  width: 260px;
  background: #FFFFFF;
  .title {
    padding: 0 18px;
    line-height: 70px;
    font-size: 16px;
    font-weight: bold;
    color: #242D33;
    border-bottom: 1px solid #EDF1F4;
  }
  .catalog {
    padding-bottom: 20px;
    .create {
      margin: 0 auto;
      width: 144px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 2px;
      background-color: #3984F5;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #1C72F1;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 6px;
        width: 10px;
        height: 10px;
        background: url("~@/assets/questionnaire/survey_icon.png") no-repeat 0 0;
      }
      span {
        font-weight: 700;
        color: #fff;
      }
    }
    .list {
      ul {
        margin-bottom: 20px;
      }
      li {
        position: relative;
        padding: 18px 0 30px 18px;
        border-bottom: 1px solid #EDF1F4;
        cursor: pointer;
        &:hover {
          .handle {
            opacity: 1;
          }
        }
      }
      .cur {
        background-color: #F9FAFD;
        box-shadow: 0 0 12px 0 rgba(36, 45, 51, 0.1);
      }
      h4 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #242D33;
        line-height: 16px;
      }
      p {
        margin-bottom: 14px;
        color: #6C7880;
        line-height: 14px;
      }
      .handle {
        position: absolute;
		width: 100%;
        opacity: 0;
        transition: all 0.3s ease;
        span {
          float: left;
          margin-right: 16px;
          color: #3984F5;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: #1C72F1;
			text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
