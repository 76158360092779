<template>
	<div class="details-calendar-main">
		<div class="text-box-content flex">
			<div class="title-type">详细地址</div>
			<div class="" style="flex: 1;">
				<div class="title-content flex flex-col-center">
					<div class="title-text-content">
						<span class="bt-text">{{num+1}}.</span>
						<span class="bt-text">{{allData.title?allData.title:'请输入标题'}}</span>
						<span class="bi-tian-text" v-if="allData.require==1">（必填）</span>
					</div>
				</div>
				<div class="radio-content flex flex-col-center">
					<el-date-picker v-model="detaText"  type="date" placeholder="选择日期"></el-date-picker>
				</div>
				<div class="edit-btn-content flex flex-col-center flex-row-between"  v-if="!isShowEdit">
					<div class="edit-btn touch-hover" @click="editClick">点击编辑问题</div>
					<div class="move-content flex flex-col-center">
					  <span class="move up" @click="moveClick(msg, 0)"><i class="ico1"></i>上移</span>
					  <span class="move down" @click="moveClick(msg, 1)"><i class="ico2"></i>下移</span>
					  <span class="move first" @click="beginClick(msg, 0)"><i class="ico3"></i>最前</span>
					  <span class="move last" @click="beginClick(msg, 1)"><i class="ico4"></i>最后</span>
					  <span class="delete" @click="deleteItemClick(msg)"><i class="ico5"></i>删除</span>
					</div>
				</div>
			</div>
		</div>
		<div class="edit-box-content " v-if="isShowEdit">
			<textarea class="textarea-input" v-model="titleInput" placeholder="请输入标题"></textarea>
			<div class="bi-tian-select">
				<el-checkbox v-model="isBiTian">要求必填</el-checkbox>
			</div>
			<button class="enter-btn touch-hover" @click="enterEditClick">完成编辑</button>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'details-calendar-item',
		props: {
			msg: {
				type: Object,
				default: {}
			},
			num: {
				type: [Number, String],
				default: 1
			},
			parentList: [],//父亲数据列表
		},
		data(){
			return{
				isBiTian: false,//是否必填
				isShowEdit: true,//是否显示编辑
				addressVlue: [],
				options: [],
				detaText: '',//地址详情
				titleInput: '',//标题
				allData: {},
				
			}
		},
		mounted() {
			this.isShowEdit = this.msg.isEdit;
			this.allData = this.msg;
		},
		methods:{
			//编辑问题
			editClick() {
				this.isShowEdit = true;
				this.titleInput = this.allData.title;
			},
			//完成编辑 
			enterEditClick() {
				this.isShowEdit = false;
				this.allData.title = this.titleInput;
				this.allData.require = this.isBiTian?1:0;
				let data = {
					title: this.allData.title,
					require: this.allData.require,
					type: this.msg.type,
					catid: this.$parent.$parent.puestionAllData.catid,
					dirid: this.$parent.$parent.puestionAllData.dirid,
					queid: this.$parent.$parent.puestionAllData.queid,
					order: this.num+1
				}
				// console.log('要传的数据',data);
				// return;
				if (this.allData.id) {
					Object.assign(data, {id: this.allData.id});
					this.$api.questionEdit(data).then(res=>{
						this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
						this.$message({type: 'success', message: '编辑成功！'});
					})
				} else {
					this.$api.questionAdd(data).then(res=>{
						this.$message({type: 'success', message: '添加成功！'});
						this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
					}).catch(error => {
            this.$emit('deleteEmpty') //如果为空，则不添加
          });
				}
			},
			//最前、最后
			beginClick(e, num) {
				let data1 = {id: '', order: ''};
				let data2 = {id: '', order: ''};
				if (num == 0) {//最前
					data1.id = e.id;
					data1.order = this.parentList[0].order;
					data2.id = this.parentList[0].id;
					data2.order = e.order;
				} else {//最后
					data1.id = e.id;
					data1.order = this.parentList[this.parentList.length-1].order;
					data2.id = this.parentList[this.parentList.length-1].id;
					data2.order = e.order;
				}
				let data = {
					move: [data1, data2]
				}
				this.$api.questionMove(data).then(res=>{
					let str = num==0?'before':'after';
					this.$emit('moveEvent', {type: str, data: e, index: this.num});
					// this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
				})
			},
			//上移、下移
			moveClick(e, num) {
				console.log('移动：', e, num, this.num, this.parentList)
				let data1 = {id: e.id, order: ''};
				let data2 = {id: '', order: ''};
				if (num==0 && this.num!=0) {//上移
					data1.order = this.parentList[this.num-1].order;
					data2.id = this.parentList[this.num-1].id;
					data2.order = e.order;	
				} else if(num==1 && this.num!=this.parentList.length-1) {//下移
					data1.order = this.parentList[this.num+1].order;
					data2.id = this.parentList[this.num+1].id;
					data2.order = e.order;	
				} else {
					return;
				}
				let data = {
					move: [data1, data2]
				}
				this.$api.questionMove(data).then(res=>{
					let str = num==0?'up':'down';
					this.$emit('moveEvent', {type: str, data: e, index: this.num});
					// this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
				})
			},
			//删除题
			deleteItemClick(e) {
				this.$api.questionDel(e.id).then(res=>{
					this.$message({type: 'success', message: '删除成功！'});
					this.$emit('moveEvent', {type: 'delete', data: e, index: this.num});
					// this.$emit('updateEvent', {data: {id: this.$parent.$parent.puestionAllData.dirid, type: this.isEdit, }, id: this.$route.query.id});
				})
			},
			//地区选择
			handleChange(e) {
				console.log(e);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.details-calendar-main{padding: 24px 0;border-bottom: 1px solid #EDF1F4;
		.text-box-content{padding: 0 20px;
			.title-type{height: 24px;background: #FFFFFF;border: 1px solid #7166FF;padding: 0 8px;line-height: 24px;font-size: 12px;color: #7166FF;margin-right: 11px;}
			.title-content{
				.title-text-content{
					.bt-text{font-size: 14px;color: #242D33;font-weight: bold;}
					.bi-tian-text{font-size: 14px;color: #242D33;}
				}
			}
			.radio-content{margin: 23px 0 18px 0;
				.el-input{width: 360px;}
				// .el-date-editor >>>.el-input__inner{width: 360px;height: 34px !important;line-height: 34px !important;font-size: 12px;}
			}
			.edit-btn-content{opacity: 0;
				.edit-btn{font-size: 12px;color: #3984F5;margin-top: 4px;}
				.move-content{border: 1px solid #DBE1E5;border-radius: 2px;line-height: 30px;
					.move{border-right: 1px solid #DBE1E5;width: 66px;height: 30px;text-align: center;
						i {display: inline-block;vertical-align: middle; width: 18px;height: 20px;background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -20px -130px;}
						.ico2 { background-position-x: -40px;}
						.ico3 { background-position-x: -60px;}
						.ico4 { background-position-x: -80px;}
						.ico5 {background-position-x: -100px;}
						
					}
					.delete{width: 66px;text-align: center;}
					span:hover {color: #3984F5;cursor: pointer;
						  i { background-position-y: -150px; }
					}
				}
			}
		}
		.edit-box-content{padding: 20px;background-color: #FAFBFD;
			.textarea-input{width: 100%;height: 100px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 14px 12px;box-sizing: border-box;}
			.bi-tian-select{padding: 18px 0;}
			.select-set-content{height: 34px;background: #EDF1F4;padding: 0 12px;margin-bottom: 12px;
				.set-title{font-size: 12px;color: #6C7880;}
				.yi-text{font-size: 12px;color: #909DA5;margin-left: 11px;}
			}
			.enter-btn{width: 100%;height: 40px;background: #3984F5;border-radius: 4px;line-height: 40px;text-align: center;color: #fff;}
		}
		&:hover {
		  .edit-btn-content {opacity: 1;}
		}
	}
	.touch-hover{cursor: pointer;}
	
</style>