<template>
	<div class="handle flex flex-col-center flex-row-between">
	  <div class="fl edit">点击编辑问题</div>
	  <div class="fr">
	    <span class="move up"><i class="ico1"></i>上移</span>
	    <span class="move down"><i class="ico2"></i>下移</span>
	    <span class="move first"><i class="ico3"></i>最前</span>
	    <span class="move last"><i class="ico4"></i>最后</span>
	    <span class="delete"><i class="ico5"></i>删除</span>
	  </div>
	</div>
	
	
	
	
</template>

<script>
	export default{
		name: 'edit-btn-item',
		props: {
			
		},
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
      .handle {
        margin-left: 74px;
        margin-top: 4px;
        opacity: 0;
        .edit {
          line-height: 30px;
          color: #3984F5;
          text-decoration: underline;
          cursor: pointer;
        }
        .fr {
          border: 1px solid #dbe1e5;
          border-right: 0;
          border-radius: 2px;
          overflow: hidden;
        }
        span {
          float: left;
          width: 65px;
          height: 28px;
          line-height: 28px;
          border-right: 1px solid #dbe1e5;
          text-align: center;
          color: #98a0a6;
          cursor: pointer;
          &:hover {
            color: #3984F5;
            i {
              background-position-y: -150px;
            }
          }
        }
        i {
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 20px;
          background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -20px -130px;
        }
        .ico2 {
          background-position-x: -40px;
        }
        .ico3 {
          background-position-x: -60px;
        }
        .ico4 {
          background-position-x: -80px;
        }
        .ico5 {
          background-position-x: -100px;
        }
      }
	
</style>