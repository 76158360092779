<template>
	<div class="select-product-main">
		<el-dialog title="选择产品" :visible="show" top="10.8vh" width="52%" :before-close="close" @open="openEvent" :close-on-click-modal="false">
			<div class="flex flex-col-center">
				<div class="input-content marg-r flex flex-col-center">
					<span class="title-text">产品分类</span>
					<el-cascader v-model="typeText" :options="proClass" :props="{value: 'id', label: 'name'}" clearable></el-cascader>
				</div>
				<div class="input-content flex flex-col-center">
					<span class="title-text">产品名称</span>
					<input class="input-text" type="text" placeholder="输入产品名称" v-model="nameText" />
				</div>
				<button class="query-btn" @click="queryClick">查询</button>
			</div>
			<div class="tabel-content" v-loading="isLoading">
				<el-table :data="tableData" ref="productTable" height="508" style="width: 100%;" :header-cell-style="{background:'#F6F7F9'}"  @selection-change="selectionChange" >
					<el-table-column type="selection" width="50"></el-table-column>
					<el-table-column label="产品信息">
						<template slot-scope="scope">
							<div class="flex flex-col-center">
								<div class="info-content flex flex-col-center">
									<img class="log-img" :src="scope.row.product_img[0]" alt="" />
									<div class="info-r">
										<p class="info-title-text">{{scope.row.name}}</p>
										<p class="info-type-text flex flex-col-center">
											<span v-for="(item, index) in scope.row.tags" :key="index">{{item}}</span>
										</p>
										<p class="info-content-text">{{scope.row.feature}}</p>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="price" label="价格" width="200">
						<template slot-scope="scope">
							<p class="text-red">￥{{scope.row.price}}元/年</p>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-content flex flex-col-center flex-row-between">
				<div class="">
					<el-checkbox v-model="isChecked" @change="selectAllClick"></el-checkbox>
					<span style="margin-left: 15px;">已选<span class="blue-text">{{checkedList.length}}</span>个</span>
				</div>
				<paging-item :key="openNum" :pageSize="pageSize" :total="pageAllSize" :pageList="pageList" @pageEvent="currentClick" @updatePageEvent="updatePage"></paging-item>
			</div>
			<span class="flex flex-col-center flex-row-center">
				<el-button type="primary" @click="enterClick">确 定</el-button>
				<el-button @click="close">取 消</el-button>
		    </span>
		  
		</el-dialog>
	</div>
</template>

<script>
	import pagingItem from "@/views/productLibrary/components/paging-item.vue"
	export default{
		name: 'select-product-popup',
		props: {
			show: false,
      checked: {
          type: Array,
          default: () => []
      }
		},
		components: {
			pagingItem
		},
		data(){
			return{
				proClass: [],//产品分类数据
				typeText: [],//选择的产品分类
				nameText: '',//输入的产品名称
				tableData: [],//产品数据列表
				isLoading: false,//是否正在加载
				pageList: [{value: 10, label: '10'},{value: 20, label: '20'},{value: 30, label: '30'}],//选择页面总条数据
				pageSize: 10,
				pageIndex: 1,
				pageAllSize: 0,//
				isChecked: false,//是否全选
				selectAllData: [],//选择的数据
        checkedList: [],//选择的数据
        whetherToSwitchPages: false,
        openNum: 0//打开弹窗次数
			}
		},
		methods:{
			//确定
			enterClick() {
				let data = this.checkedList
				// let data = this.selectAllData.map(item => item.id)

        // console.log(data, 'data=========')
				this.$emit('enterEvent', data);
        this.pageIndex = 1;
        this.initData();
        ++this.openNum
				this.close();
			},
			initData() {
				this.isLoading = true;
				let data = {
					page: this.pageIndex,
					limit: this.pageSize,
					name: this.nameText,
					cate_node_id: this.typeText.length>0?this.typeText[this.typeText.length-1]:'',
          status:1
				}
				this.$api.productList(data).then(res=>{
					this.isLoading = false;
					this.tableData = res.data.data;
					this.pageAllSize = res.data.count;
					// for(let i=0; i < this.tableData.length; i++) {
					// 	this.tableData[i]['checked'] = false;
					// }

          this.$nextTick(() => {


            // console.log(this.checked, this.checkedList, 'checkedList')
            const checkedList = this.tableData.filter(item => {
              return this.checkedList.includes(item.id)
            })

            this.toggleSelection(checkedList)
          })
				}).catch(err=>{
					this.isLoading = false;
				})
			},
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.productTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.productTable.clearSelection();
        }
      },
			//打开弹窗
			openEvent() {
        this.checkedList = []
        this.selectAllData = []
        // console.log(this.checkedList, this.selectAllData, this.checked, '打开弹窗')
        this.checkedList.length > 0 || this.checkedList.push(...this.checked)

				//产品分类
				this.$api.cateList().then(res=>{
					this.proClass = res.data;
				})
				this.initData();
			},
			//查询
			queryClick() {
				this.pageIndex = 1;
				this.initData();
			},
			//全选事件
			selectionChange(e) {
        // console.log(e, '切换页面时进入2')
        if (this.whetherToSwitchPages) {
          this.whetherToSwitchPages = false;
          return false;
        }
				this.selectAllData[this.pageIndex - 1] = e;
        // 处理数组变化的逻辑
        let list = [];
        list.push(...this.checked);
        this.selectAllData.forEach(item => {
          item.forEach(i => {
            list.push(i.id)
          })
        })

        // console.log(list, 'list')

        this.checkedList = [...new Set(list)];
        // console.log(this.selectAllData, 'this.selectAllData')
			},
			//全选
			selectAllClick(e) {
				if (e) {
					this.$refs.productTable.toggleAllSelection();
				} else {
					this.$refs.productTable.clearSelection();
				}
			},
			//页面更改时
			currentClick(e) {
				this.pageIndex = e;
          // console.log(e,'切换页面时进入1')
        this.whetherToSwitchPages = true;
				this.initData();
			},
			//页面数量更改时
			updatePage(e) {
				this.pageIndex = 1;
				this.pageSize = e;
        this.whetherToSwitchPages = true;
				this.initData();
			},
			close() {
        this.pageIndex = 1;
        this.initData();
        ++this.openNum
				this.$emit('close');
			},
			handleClose(done) {
        ++this.openNum;
				done();
				this.$emit('close');
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.select-product-main{
		.input-content{
			.title-text{font-size: 12px;color: #242D33;margin-right: 12px;}
			.el-cascader{width: 240px;border-radius: 4px;}
			.input-text{width: 240px;height: 34px;background: #FFFFFF;border-radius: 4px;border: 1px solid #CEDAE0;padding: 0 12px;box-sizing: border-box;}
		}
		.marg-r{margin-right: 30px;}
		.query-btn{width: 64px;height: 34px;background: #3984F5;border-radius: 4px;margin-left: 10px;font-size: 12px;color: #fff;line-height: 34px;}
		.tabel-content{margin-top: 20px;border: 1px solid #DCE5EC;
			.text-red{color: #FD5451;}
			.text-blue{color: #3984F5;}
			.info-content{
				.log-img{width: 84px;height: 84px;background: #CEDAE0;margin-right: 20px;}
				.info-title-text{font-size: 16px;color: #242D33;}
				.info-type-text{margin-top: 10px;
					span{height: 26px;background: #EFF5FF;margin-right: 8px;line-height: 26px;font-size: 12px;color: #3984F5;padding: 0 8px;}
				}
				.info-content-text{font-size: 12px;color: #6C7880;margin-top: 10px;overflow:hidden;word-wrap: break-word;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
			}
			.btn-content{margin-right: 30px;
				.edit-img{width: 12px;height: 12px;margin-right: 4px;}
				&:hover{cursor: pointer;}
			}
		}
		.page-content{margin-top: 20px;
			.blue-text{font-size: 12px;color: #3984F5;}
		}
		
		
		
	}
	::v-deep(.el-dialog__body){padding: 20px 20px 30px;}
	::v-deep(.el-range__icon){display: none;}
	::v-deep(.el-input__inner){height: 34px;line-height: 34px;font-size: 12px;}
	::v-deep(.el-range-input){font-size: 12px;}
	
</style>