<template>
	<div class="edit-catalogue-main">
		<el-dialog title="编辑问卷目录" :visible="show" top="10.8vh" width="500px" :before-close="handleClose" @open="openClick" :close-on-click-modal="false">
			<div class="scoll-content">
				<p class="title-text">当前题目：{{msg.num}}.{{msg.data.title?msg.data.title:'请输入标题'}}</p>
				<p class="title-text">当选中以下指定选项时，在其后增加填空项，效果如下图：</p>
				<div class="tabel-content">
					<el-table :data="tableData" ref="productTable" style="width: 100%;" border :header-cell-style="{background:'#F6F7F9'}"  @selection-change="selectionChange" >
						<el-table-column label="选项" prop="title"></el-table-column>
						<el-table-column prop="price" label="选择" width="200">
							<template slot-scope="scope">
								<el-radio :label="scope.$index" v-model="isCheck" @change.native="selectRadio(scope.$index, scope.row)" >{{''}}</el-radio>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="radio-s-content">
					<div  v-for="(item, index) in tableData" :key="index">
						<el-radio :label="index" v-model="isCheck" >{{item.title}}</el-radio>
						<input class="radio-input" type="text" v-if="isCheck" />
					</div>
				</div>
			</div>
			
			<span class="flex flex-col-center flex-row-center">
				<el-button type="primary" @click="enterClick">保存</el-button>
				<el-button @click="close">取消</el-button>
		    </span>
		  
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'edit-catalogue-popup',
		props: {
			show: false,
			msg: {
				type: Object,
				default: {}
			}
		},
		data(){
			return{
				tableData: [],
				isCheck: null,
			}
		},
		mounted() {
		},
		methods:{
			enterClick() {
				
				this.close();
			},
			//单选事件
			selectRadio(index, e) {
				console.log(index, e);
			},
			//打开弹窗
			openClick() {
				console.log('弹窗：', this.msg);
				this.tableData = this.msg.data.options;				
			},
			close() {
				this.$emit('close');
			},
			handleClose(done) {
				done();
				this.$emit('close');
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.edit-catalogue-main{
		.scoll-content{height: 360px;overflow: scroll;scrollbar-width: none;}
		.title-text{font-size: 14px;font-weight: bold;color: #242D33;line-height: 24px;}
		.tabel-content{margin-top: 18px;padding-bottom: 30px;border-bottom: 1px solid #EDF1F4;}
		.radio-s-content{padding: 20px 0;
			.el-radio{display: block;margin-bottom: 12px;}
			.radio-input{width: 400px;height: 34px;background: #FFFFFF;border-radius: 2px;border: 1px solid #CEDAE0;margin-bottom: 18px;}
		}
		
		
	}
	
</style>