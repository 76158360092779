<template>
	<div class="paging-main flex flex-col-center flex-row-end">
		<el-pagination 
		background 
		:page-size.sync="pageSize" 
		:total="total" 
		:pager-count="11" 
		layout="prev, pager, next" 
		@current-change="currentClick"
		></el-pagination>
		<div class="page-btn-content flex flex-col-center">
			<span class="m-text">每页</span>
			<el-select v-model="selectPage" placeholder="请选择" @change="selectClick">
				<el-option v-for="item in pageList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
			</el-select>
			<span class="m-text">条</span>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'paging-item',
		props: {
			//每页的分页数量
			pageSize: {
				type: [Number, String],
				default: 10
			},
			//总条数
			total: {
				type: [Number, String],
				default: 10
			},
			//控制当前页面显示的总条数
			pageList: {
				type: Array,
				default: []
			}
		},
		data(){
			return{
				selectPage: 10,
			}
		},
		methods:{
			//翻页
			currentClick(e) {
				this.$emit('pageEvent', e);
			},
			//选择的页面数量
			selectClick(e) {
				this.$emit('updatePageEvent', e);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.paging-main{
		.page-btn-content{margin-left: 8px;
			.m-text{font-size: 12px;color: #242D33;margin: 0 9px;}
			.el-select{width: 70px;height: 34px;background: #FFFFFF;border-radius: 2px;font-size: 12px;}
		}
	}
	::v-deep(.number){background: #fff !important;border: 1px solid #CEDAE0;}
	::v-deep(.active){background: #3984F5 !important;color: #fff;}
	::v-deep(.btn-prev){background:#fff !important;border: 1px solid #CEDAE0;}
	::v-deep(.btn-next){background:#fff !important;border: 1px solid #CEDAE0;}
	
</style>