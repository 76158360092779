<template>
  <div class="right-topic">
    <ul class="tab clearfix">
      <li :class="[tabRightIndex === 0 ? 'cur' : '']" @click="tabClick(0)">自定题目</li>
      <li :class="[tabRightIndex === 1 ? 'cur' : '']" @click="tabClick(1)">题库选题</li>
    </ul>
    <p class="tips" v-if="currentIsFixed!=0">预设问卷目录不支持配置题目，自定义题目请在左侧目录栏手动创建目录后再进行增加。</p>
    <div v-if="currentIsFixed==0">
      <el-scrollbar class="quest-scroll" v-if="tabRightIndex === 0">
        <div class="topic">
          <ul class="list">
            <li>
              <div class="tt">选择题</div>
              <div class="item clearfix">
                <p class="type1" @click="selectClick(0)"><i class="ico1"></i>单项选择</p>
                <p class="type2" @click="selectClick(1)"><i class="ico2"></i>多项选择</p>
              </div>
            </li>
            <li>
              <div class="tt">填空题</div>
              <div class="item clearfix">
                <p class="type3" @click="selectClick(2)"><i class="ico3"></i>单项填空</p>
                <p class="type4" @click="selectClick(3)"><i class="ico4"></i>多项填空</p>
              </div>
            </li>
            <li>
              <div class="tt">组合题</div>
              <div class="item clearfix">
                <p class="type5" @click="selectClick(4)"><i class="ico5"></i>单选填空</p>
                <p class="type6" @click="selectClick(5)"><i class="ico6"></i>详细地址</p>
                <p class="type7" @click="selectClick(6)"><i class="ico7"></i>详细日期</p>
              </div>
            </li>
          </ul>
        </div>
      </el-scrollbar>
      <el-scrollbar class="quest-scroll" v-else>
        <div class="que-bank">
			<div class="search">
				<input v-model="searchValue" type="text" placeholder="输入题目进行搜索" @keyup.enter="sendValue"/>
				<button @click="sendValue"></button>
				<i class="del" v-if="searchValue.length" @click="searchValue = ''"></i>
			</div>
			<div class="list">
			
				<div class="list-content" v-for="(item, index) in allData" :key="index">
					<div class="list-title-content flex flex-col-center">
						<img class="title-icon" src="@/assets/questionnaire/base_icon.png"/>
						<div class="">
							<p class="list-title-text">{{item.title}}</p>
							<p class="num-text">共{{item.num}}题</p>
						</div>
					</div>
					<div class="item-content" v-for="(items, indexs) in item.cateList" :key="items.id">
						<div class="item-title-content flex flex-col-center flex-row-between">
							<span class="item-title-text">{{items.title}}</span>
							<span class="item-num-text">共{{items.num}}题</span>
						</div>
						<div class="item-s-title flex flex-col-center" v-for="(itemc, indexc) in items.queList" :key="itemc.id" @click="selectPric(itemc,indexc)">{{(indexc+1)+'.'+itemc.title}}</div>
					</div>
				</div>
				
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'rightTopic',
		props: ['currentIsFixed', 'tabRightIndex'],
		data() {
			return {
				searchValue: '' ,// 搜索框值
				allData: [],//所有题
			}
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				
			},
			//题库选题
			selectPric(e, index) {	
				this.$emit('selectEvent', {type: e.type, data: e, touchType: 'tiku'});
			},
			//选择题目
			selectClick(type) {
				let data = {};
				if (type==0) {
					data = {
						type: 0,
						title: '',
						options: [{title: '', isCheck: false}, {title: '', isCheck: false}],
						require: 0,
						isEdit: true
					}
				}
				if (type==1) {
					data = {
						type: 1,
						title: '',
						options: [{title: '', isCheck: false}, {title: '', isCheck: false}],
						require: 0,
						isEdit: true
					}
				}
				if (type==2) {
					data = {
						type: 2,
						title: '',
						options: [{content: ''}],
						require: 0,
						isEdit: true
					}
				}
				if (type==3) {
					data = {
						type: 3,
						title: '',
						options: [{content: ''}, {content: ''}],
						require: 0,
						isEdit: true
					}
				}
				if (type==4) {
					data = {
						type: 4,
						title: '',
						options: [{title: '', isCheck: false}, {title: '', isCheck: false, content: ''}],
						require: 0	,
						isEdit: true
					}
				}
				if (type==5) {
					data = {
						type: 5,
						title: '',
						text: '',
						require: 0,
						isEdit: true
					}
				}
				if (type==6) {
					data = {
						type: 6,
						title: '',
						text: '',
						require: 0,
						isEdit: true
					}
				}
				this.$emit('selectEvent', {type: type, data: data, touchType: 'timu'});
			},
			tabClick(index) {
				if (index==1) {
					this.$api.questionnaire_basic_bank({title: this.searchValue}).then(res => {
						this.allData = res.data;
					});
				}
				this.$emit('get-tabClick', index);
			},
			//搜索
			sendValue() {
				console.log('搜索');
				this.tabClick(1);
			}
		}
	}
</script>

<style lang="scss" scoped>
.right-topic {
  position: absolute;
  right: 18px;
  top: 106px;
  bottom: 18px;
  width: 260px;
  background: #FFFFFF;
  .title {
    padding: 0 18px;
    line-height: 70px;
    font-size: 16px;
    font-weight: bold;
    color: #242D33;
    border-bottom: 1px solid #EDF1F4;
  }
  .tab {
    padding: 18px 30px;
    border-bottom: 1px solid #EDF1F4;
    li {
      float: left;
      width: 50%;
      line-height: 34px;
      text-align: center;
      background-color: #edf1f4;
      color: #6C7880;
      font-weight: 700;
      cursor: pointer;
    }
    .cur {
      background-color: #3984F5;
      color: #FFFFFF;
    }
  }
  .tips {
    padding: 20px 16px 0;
    font-size: 14px;
    color: #444F57;
    line-height: 26px;
  }
  .topic {
    .list {
      li {
        padding: 15px 0 18px 18px;
        border-bottom: 1px solid #EDF1F4;
        transition: all 0.3s ease;
        &:hover {
          background-color: #fafbfd;
          box-shadow: 0 0 12px rgba(36, 45, 51, 0.1);
        }
      }
      .tt {
        margin: 0 0 6px;
        font-size: 14px;
        font-weight: 700;
        color: #242D33;
      }
      .item {
        p {
          float: left;
          margin-right: 30px;
          width: 90px;
          line-height: 30px;
          color: #444F57;
          cursor: pointer;
          &:hover {
            color: #3984F5;
            i {
              background-position-y: -110px;
            }
          }
        }
        i {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 4px;
          margin-right: 2px;
          width: 20px;
          height: 20px;
          background: url("~@/assets/questionnaire/survey_icon.png") no-repeat 0 -90px;
        }
        .ico2 {
          background-position-x: -20px;
        }
        .ico3 {
          background-position-x: -40px;
        }
        .ico4 {
          background-position-x: -60px;
        }
        .ico5 {
          background-position-x: -80px;
        }
        .ico6 {
          background-position-x: -120px;
        }
        .ico7 {
          background-position-x: -100px;
        }
      }
    }
  }
	.que-bank {
		.search {
		  position: relative;
		  margin: 16px 18px 18px;
		  height: 32px;
		  border-radius: 2px;
		  border: 1px solid #CEDAE0;
		  overflow: hidden;
		  input {
			padding: 0 50px 0 10px;
			width: 100%;
			height: 32px;
			line-height: 32px;
			box-sizing: border-box;
		  }
		  button {
			position: absolute;
			right: 8px;
			top: 7px;
			width: 20px;
			height: 20px;
			background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -40px -70px;
			&:hover {
			  background-position-x: -60px;
			}
		  }
		  i {
			position: absolute;
			right: 32px;
			top: 10px;
			width: 15px;
			height: 15px;
			background: url("~@/assets/questionnaire/survey_icon.png") no-repeat -100px -50px;
			cursor: pointer;
		  }
    }
	.list {	}
	}
	
	.list-content{
		.list-title-content{height: 76px; padding: 0 18px;border-bottom: 1px solid #EDF1F4;background: #FAFBFD;
			.title-icon{width: 36px;height: 36px;margin-right: 11px;}
			.list-title-text{font-size: 14px;font-weight: bold;color: #242D33;}
			.num-text{font-size: 12px;color: #909DA5;margin-top: 3px;}
		}
		.item-content{
			.item-title-content{height: 55px;padding: 0 18px;border-bottom: 1px solid #EDF1F4;
				.item-title-text{font-size: 12px;color: #242D33;font-weight: bold;}
				.item-num-text{font-size: 12px;color: #909DA5;}
			}
			.item-s-title{font-size: 12px;color: #242D33;border-bottom: 1px solid #EDF1F4;min-height: 51px;padding: 0 18px;
				&:hover{box-shadow: 0px 0px 12px 0px rgba(36,45,51,0.1);background: #FAFBFD;cursor: pointer;}
			}
		}
		
	}
  
  
  
  
}
</style>
